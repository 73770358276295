import type { AxiosError } from 'axios';
import instance from '../../../app-authentication/service/authentication-interceptor';
import type {
  CertificateStatus,
  DeviceModel,
  LogResponse,
  Page,
  PermissionRequestResponse,
} from '../../model';
import type { Connection } from '../../model/connection.model';
import type { EventLog } from '../../model/event-log.model';
import type { SearchObject } from '../../model/search.model';

export async function getDevice(
  body: SearchObject | undefined,
  signal: AbortSignal | undefined,
): Promise<Page> {
  return instance
    .post<Page>(
      `${process.env.REACT_APP_ISP_API_DEVICE_BASE_URL}/device`,
      body,
      { signal },
    )
    .then((response) => {
      return response.data;
    });
}

export async function getDeviceLog(
  deviceId: string,
  deviceModel: DeviceModel,
): Promise<LogResponse[]> {
  return instance
    .get<LogResponse[]>(
      `${process.env.REACT_APP_ISP_API_DEVICE_BASE_URL}/device/logs/${deviceId}?deviceModel=${deviceModel}`,
    )
    .then((response) => response.data)
    .catch((error: AxiosError) => {
      throw new Error(`${error.message}`);
    });
}

export async function requestLogUpload(
  deviceId: string,
  deviceModel: DeviceModel,
  path?: string,
): Promise<void> {
  const pathParameter = path ? `&path=${path}` : '';
  await instance
    .post(
      `${process.env.REACT_APP_ISP_API_DEVICE_BASE_URL}/device/logs/upload-logs/${deviceId}?deviceModel=${deviceModel}${pathParameter}`,
    )
    .catch((error: AxiosError) => {
      throw new Error(`${error.message}`);
    });
}

export async function moveDevice(
  deviceId: string | undefined,
  environment: string | undefined,
): Promise<void> {
  await instance
    .post(
      `${process.env.REACT_APP_ISP_API_DEVICE_BASE_URL}/device/environment`,
      {
        deviceId,
        environment,
      },
    )
    .catch((error: AxiosError) => {
      throw new Error(`${error.message}`);
    });
}

export async function executeCommand(
  deviceId: string | undefined,
  deviceModel: DeviceModel,
  command: string,
): Promise<string> {
  return instance
    .post<string>(
      `${process.env.REACT_APP_ISP_API_DEVICE_BASE_URL}/device/command`,
      {
        deviceId,
        deviceModel,
        command,
      },
    )
    .then((response) => response.data)
    .catch((error: AxiosError) => {
      throw new Error(`${error.message}`);
    });
}

export async function changeTag(
  tag: string | undefined,
  deviceId: string | undefined,
): Promise<void> {
  await instance
    .post(`${process.env.REACT_APP_ISP_API_DEVICE_BASE_URL}/device/tag`, {
      tag,
      deviceId,
    })
    .catch((error: AxiosError) => {
      throw new Error(`${error.message}`);
    });
}

export async function changeSetting(
  deviceId: string | undefined,
  deviceModel: DeviceModel,
  name: string,
  value: boolean,
): Promise<string> {
  return instance
    .post<string>(
      `${process.env.REACT_APP_ISP_API_DEVICE_BASE_URL}/device/setting`,
      {
        deviceId,
        deviceModel,
        name,
        value,
      },
    )
    .then((response) => response.data)
    .catch((error: AxiosError) => {
      throw new Error(`${error.message}`);
    });
}

export async function getCertificateStatus(
  deviceId: string,
  deviceModel: DeviceModel,
  signal?: AbortSignal,
): Promise<CertificateStatus> {
  return instance
    .get<CertificateStatus>(
      `${process.env.REACT_APP_ISP_API_DEVICE_BASE_URL}/device/certificate-status/${deviceId}?deviceModel=${deviceModel}`,
      { signal },
    )
    .then((response) => response.data);
}

export async function renewDeviceCertificate(
  deviceId: string,
  deviceModel: string,
  signal?: AbortSignal,
): Promise<void> {
  await instance.post(
    `${process.env.REACT_APP_ISP_API_DEVICE_BASE_URL}/device/renew-certificate/${deviceId}?deviceModel=${deviceModel}`,
    {},
    { signal },
  );
}

export async function requestReadOnlyPermissions(
  deviceId: string,
  deviceModel: string,
  signal?: AbortSignal,
): Promise<PermissionRequestResponse> {
  return instance
    .post<PermissionRequestResponse>(
      `${process.env.REACT_APP_ISP_API_DEVICE_BASE_URL}/device/permissions/${deviceId}/read-only?deviceModel=${deviceModel}`,
      {},
      { signal },
    )
    .then((response) => response.data)
    .catch((error: AxiosError) => {
      throw new Error(`${error.message}`);
    });
}

type GetConnectionHistoryOptions = {
  deviceId: string;
  deviceModel: DeviceModel;
  from?: Date;
  to?: Date;
  signal?: AbortSignal;
};

export async function getConnectionHistory({
  deviceId,
  deviceModel,
  from,
  to,
  signal,
}: GetConnectionHistoryOptions): Promise<Connection[]> {
  const searchParams = new URLSearchParams([['deviceModel', deviceModel]]);

  if (from) {
    searchParams.set('from', from.toISOString());
  }
  if (to) {
    searchParams.set('to', to.toISOString());
  }

  return instance
    .get<
      Connection[]
    >(`${process.env.REACT_APP_ISP_API_DEVICE_BASE_URL}/device/connection-history/${deviceId}?${searchParams}`, { signal })
    .then((response) => response.data);
}

export async function getEventLog(
  deviceId: string,
  deviceModel: string,
  signal?: AbortSignal,
): Promise<EventLog> {
  return instance
    .get<EventLog>(
      `${process.env.REACT_APP_ISP_API_DEVICE_BASE_URL}/device/event-log/${deviceId}?deviceModel=${deviceModel}`,
      { signal },
    )
    .then((response) => response.data);
}
