import type { UseMutationResult, UseQueryResult } from '@tanstack/react-query';
import { useMutation, useQuery } from '@tanstack/react-query';
import type { AxiosError, AxiosResponse } from 'axios';
import type { TabsResponse } from '../../../app-shell/model/app.models';
import type { CustomerManagementCustomer } from '../../model/customer-account.model';
import type {
  CustomerProfileUid,
  DisconnectCustomerData,
} from '../../model/customer-profile.model';
import {
  disconnectCustomerFromDevice,
  getCustomerManagementTabs,
  getCustomers,
  removeDeputy,
  resendVerificationEmail,
} from '../customer-api-client/customer-api-client';

export function useCustomers(): UseQueryResult<
  CustomerManagementCustomer[],
  Error
> {
  return useQuery<CustomerManagementCustomer[], Error>({
    queryKey: ['customers'],
    queryFn: async ({ signal }) => getCustomers(signal),
    refetchOnWindowFocus: false,
  });
}

export function useCustomerManagementTabs(): UseQueryResult<
  TabsResponse[],
  Error
> {
  return useQuery<TabsResponse[], Error>({
    queryKey: ['customerManagementTabs'],
    queryFn: async ({ signal }) => getCustomerManagementTabs(signal),
    refetchOnWindowFocus: false,
  });
}

export function useDisconnectCustomerFromDevice(): UseMutationResult<
  AxiosResponse<unknown, unknown>,
  AxiosError,
  DisconnectCustomerData,
  unknown
> {
  return useMutation({
    mutationFn: async (formData: DisconnectCustomerData) =>
      disconnectCustomerFromDevice(formData),
  });
}

export function useResendVerificationEmail(): UseMutationResult<
  AxiosResponse<CustomerProfileUid, unknown>,
  AxiosError,
  { customerId: string },
  unknown
> {
  return useMutation({
    mutationFn: async ({ customerId }) => resendVerificationEmail(customerId),
  });
}

export function useRemoveDeputy(): UseMutationResult<
  AxiosResponse<unknown, unknown>,
  AxiosError,
  { deputyId: string; subscriptionId: string },
  unknown
> {
  return useMutation({
    mutationFn: async ({ deputyId, subscriptionId }) =>
      removeDeputy(deputyId, subscriptionId),
  });
}
