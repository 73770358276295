import styled from '@emotion/styled';
import Typography from '@mui/material/Typography';
import type { DefinedUseQueryResult } from '@tanstack/react-query';
import { useCallback, useMemo, useState } from 'react';
import type { Dispatch, ReactNode, SetStateAction } from 'react';
import { useTranslation } from 'react-i18next';
import { StyledAlert } from '../../../../base/stihl-material-ui/components/stihl-snackbar-alert/stihl-snackbar-alert';
import StihlIconInfo from '../../../../base/stihl-material-ui/icons/stihl-icon-info';
import { stihlColor } from '../../../../base/stihl-material-ui/theme/stihl-style-guide';
import { TabsType } from '../../../app-shell/model/app.models';
import TabsBase, { TabPanel } from '../../../app-shell/ui/tabs-base';
import { CardType } from '../../model';
import type {
  Card as CardValue,
  DiagnosisSessionData,
  Meta,
} from '../../model/device.model';
import type { DeviceSearchResult } from '../../model/search.model';
import Card from '../card/card';
import { DeviceTabsContext } from './device-tabs.context';
import { Container, ContainerContent } from './tabs-shared';

const NamePositioner = styled.div`
  position: absolute;
  font-size: 1.25rem;
  background-color: ${stihlColor.greyMid};
  inset-inline-end: 1rem;
`;

const NameStyler = styled.span`
  color: ${stihlColor.fontGrey};
  font-weight: bold;
`;

export const MessageWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  inline-size: 100%;
  padding-block-start: 5%;
`;

export type TabsComponentProps = {
  deviceData: DefinedUseQueryResult<DeviceSearchResult>;
  diagnosisDataArray: DiagnosisSessionData[] | undefined;
  setDiagnosisDataArray: Dispatch<
    SetStateAction<DiagnosisSessionData[] | undefined>
  >;
};

export const AdaptedStyledAlert = styled(StyledAlert)`
  margin: 0 0 1rem;
`;

// eslint-disable-next-line max-lines-per-function
const DeviceTabsDeviceDetails = ({
  deviceData,
  diagnosisDataArray,
  setDiagnosisDataArray,
}: TabsComponentProps): ReactNode => {
  const [visibleTab, setVisibleTab] = useState<TabsType>(
    TabsType.SupportFunctions,
  );

  const { t } = useTranslation();

  const tabs = useMemo(
    () =>
      deviceData.data.tabs.filter(
        (tab) => tab.type === undefined || tab.type === visibleTab,
      ),
    [deviceData.data.tabs, visibleTab],
  );

  const activeTabInitial = tabs.length > 1 ? 1 : 0;

  const [activeTab, setActiveTab] = useState(activeTabInitial);

  const openTab = useCallback(
    (tabName: string) => {
      const tabIndex = tabs.findIndex((tab) => tab.title === tabName);

      if (tabIndex >= 0) {
        setActiveTab(tabIndex);
      }
    },
    [tabs],
  );

  const setVisibleTabAndOpenIt = useCallback(
    (tabType: TabsType) => {
      setVisibleTab(tabType);

      const newActiveTab = deviceData.data.tabs
        .filter((tab) => tab.type === undefined || tab.type === tabType)
        .findIndex((tab) => tab.type === tabType);

      if (newActiveTab >= 0) {
        setActiveTab(newActiveTab);
      }
    },
    [deviceData.data.tabs],
  );

  function handleChange(
    _event: React.ChangeEvent<unknown>,
    newValue: number,
  ): void {
    setActiveTab(newValue);
  }

  const tabTitles = tabs.map((tab) => ({
    label: t(tab.title),
    hasBadge: false,
  }));

  const metadata = deviceData.data.meta;
  const reloadData = (): void => void deviceData.refetch();

  function renderInColumns(cards: CardValue[], meta: Meta): ReactNode {
    const left = cards.slice(0, Math.round(cards.length / 2));
    const right = cards.slice(Math.round(cards.length / 2));

    return (
      <Container
        key={left[0].title}
        isFullWidth={
          cards.length <= 1 ||
          cards[0].type === CardType.Search ||
          cards[0].type === CardType.QualityOfMobileConnection ||
          cards[0].type === CardType.ConnectionHistory
        }
      >
        {[left, right].map((bucket, index) => (
          // eslint-disable-next-line react/no-array-index-key
          <ContainerContent key={index}>
            {bucket.map((group) => (
              <Card
                key={group.title}
                card={group}
                meta={meta}
                isDataLoading={deviceData.isLoading}
                reloadData={reloadData}
                dataUpdatedAt={deviceData.dataUpdatedAt}
                openTab={setVisibleTabAndOpenIt}
                diagnosisDataArray={diagnosisDataArray}
                setDiagnosisDataArray={setDiagnosisDataArray}
              />
            ))}
          </ContainerContent>
        ))}
      </Container>
    );
  }

  return (
    <DeviceTabsContext.Provider value={{ openTab }}>
      <TabsBase value={activeTab} handleChange={handleChange} tabs={tabTitles}>
        <NamePositioner>
          {metadata.customerName && (
            <NameStyler>{metadata.customerName}&lsquo;s</NameStyler>
          )}
          <b> {metadata.deviceModel}</b>
        </NamePositioner>
      </TabsBase>

      {tabs.map((tab, index) => (
        <TabPanel key={tab.title} value={activeTab} index={index}>
          {(tab.title === 'tabs.logs' ||
            tab.title === 'tabs.supportFunctions') && (
            <AdaptedStyledAlert
              severity="info"
              icon={<StihlIconInfo />}
              data-testid="offlineDeviceHint"
            >
              {t('tabs.offlineDeviceHint')}
            </AdaptedStyledAlert>
          )}
          {metadata.isDeviceStolen && tab.title === 'tabs.overview' && (
            <AdaptedStyledAlert severity="error">
              {t('deviceDetails.deviceStolen')}
            </AdaptedStyledAlert>
          )}
          {tab.cards.length > 0 ? (
            renderInColumns(tab.cards, metadata)
          ) : (
            <MessageWrapper data-testid="noResultMessage">
              <Typography>{t('searchErrors.noResult')}</Typography>
            </MessageWrapper>
          )}
        </TabPanel>
      ))}
    </DeviceTabsContext.Provider>
  );
};

export default DeviceTabsDeviceDetails;
