import type { FC } from 'react';
import type { Customer, Meta } from '../../../model';
import CardFieldCustomerDetailsCustomer from './card-field-customer-details-customer';
import CardFieldCustomerDetailsError from './card-field-customer-details-error';
import CardFiledCustomerDetailsNoCustomerConnected from './card-field-customer-details-no-customer-connected';

type CardFieldCustomerDetailsProps = {
  value: Customer;
  meta: Meta;
};

const CardFieldCustomerDetails: FC<CardFieldCustomerDetailsProps> = ({
  value,
  meta,
}) => {
  if (value.hasError) {
    return <CardFieldCustomerDetailsError />;
  }

  if (!value.hasCustomersAssigned) {
    return (
      <CardFiledCustomerDetailsNoCustomerConnected
        deviceSerialNumber={meta.deviceSerialNumber}
      />
    );
  }

  return (
    <CardFieldCustomerDetailsCustomer
      customer={value}
      isUserDealer={meta.hasMatchingPreferredDealer}
      hasCustomerAccess={meta.hasCustomerAccess}
    />
  );
};

export default CardFieldCustomerDetails;
