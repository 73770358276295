import styled from '@emotion/styled';
import MuiCard from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import type { JSX } from 'react';
import { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { dateTimeFormattingParamsDefault } from '../../../../../base/date-formatting/date-formatting';
import StihlButtonPrimaryLoading from '../../../../../base/stihl-material-ui/components/stihl-button/stihl-button-primary-loading';
import StihlCardMedia from '../../../../../base/stihl-material-ui/components/stihl-card-media/stihl-card-media';
import StihlTooltipCentered from '../../../../../base/stihl-material-ui/components/stihl-tooltip/stihl-tooltip-centered';
import StihlIconRefresh from '../../../../../base/stihl-material-ui/icons/stihl-icon-refresh';
import StihlBox from '../../../../../base/stihl-material-ui/images/stihl-box.png';
import StihlStatusImowIA01Evo from '../../../../../base/stihl-material-ui/images/stihl-imow-ia01-evo.svg';
import StihlStatusImowIA01Pro from '../../../../../base/stihl-material-ui/images/stihl-imow-ia01-pro.svg';
import StihlStatusImowIA01 from '../../../../../base/stihl-material-ui/images/stihl-imow-ia01.svg';
import StihlStatusImowIA02Evo from '../../../../../base/stihl-material-ui/images/stihl-imow-ia02-evo.svg';
import StihlStatusImowIA02 from '../../../../../base/stihl-material-ui/images/stihl-imow-ia02.svg';
import StihlStatusLegacyMower from '../../../../../base/stihl-material-ui/images/stihl-legacy-mower.svg';
import { stihlColor } from '../../../../../base/stihl-material-ui/theme/stihl-style-guide';
import { useAlertStore } from '../../../../app-alert/service/alert-provider';
import type { Card, Meta } from '../../../model/device.model';
import { DeviceModel, DeviceType } from '../../../model/device.model';
import DeviceStatusTabContent, {
  DeviceStatusTabTitle,
  // eslint-disable-next-line import/max-dependencies
} from './device-status-card-tabs';

const CardHeader = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  min-block-size: 5rem;
  padding: 0.75rem 1rem;
  border-block-end: 4px solid ${stihlColor.stihlOrange};
`;

const StyledCardContent = styled(CardContent)`
  padding: 1rem;
`;

const StyledIconButton = styled(IconButton)`
  padding: 0;

  &:hover {
    rect {
      fill: ${stihlColor.stihlOrangeDark};
    }
  }
`;

const StyledLoadingButton = styled(StihlButtonPrimaryLoading)`
  min-inline-size: 40px;
  block-size: 40px;
  padding: 0;
  border-radius: 50%;
`;

const IMOW_BG_GRADIENT = 'linear-gradient(180deg, #bfd98c 0%, #749f4a 100%)';
const BOX_BG_GRADIENT = 'linear-gradient(180deg, #fee2cd 0%, #f37a1f 100%)';
const NO_STATUS_BG_GRADIENT =
  'linear-gradient(180deg, #dadada 0%, #505050 100%)';

export type DeviceStatusCardProps = {
  meta: Meta;
  cardData: Card;
  lastUpdated: number | undefined;
  refreshData: () => void;
  isLoading: boolean;
};

// eslint-disable-next-line max-lines-per-function
const DeviceStatusCard = ({
  meta,
  cardData,
  lastUpdated,
  refreshData,
  isLoading,
}: DeviceStatusCardProps): JSX.Element => {
  const { t } = useTranslation();
  const [activeTab, setActiveTab] = useState(0);
  const [_, setAlert] = useAlertStore();

  useEffect(() => {
    if (meta.liveStatusErrorMessage?.includes('504')) {
      setAlert({
        isOpen: true,
        severity: 'warning',
        message: t('deviceDetails.liveStatusTimeoutErrorAlert'),
      });
    } else if (meta.liveStatusErrorMessage) {
      setAlert({
        isOpen: true,
        severity: 'error',
        message: t('deviceDetails.liveStatusErrorAlert', {
          errorMessage: meta.liveStatusErrorMessage,
        }),
      });
    }
  }, [meta.liveStatusErrorMessage, setAlert, t]);

  const hasErrorStatus =
    cardData.cardTabs.length > 0 &&
    cardData.cardTabs[0].cardFields.some(
      (field) => field.title === 'deviceDetails.deviceStatusErrorTitle',
    );

  function handleTabsChange(
    __: React.ChangeEvent<unknown>,
    newValue: number,
  ): void {
    setActiveTab(newValue);
  }

  function renderCardImage(): JSX.Element {
    let image: string;
    let title: string;
    let backgroundImage = hasErrorStatus
      ? NO_STATUS_BG_GRADIENT
      : IMOW_BG_GRADIENT;

    if (meta.deviceModel === DeviceModel.connectedBox) {
      image = StihlBox;
      title = 'Connected Box CE02';
      backgroundImage = hasErrorStatus
        ? NO_STATUS_BG_GRADIENT
        : BOX_BG_GRADIENT;
    } else if (
      meta.deviceModel === DeviceModel.Legacy6301 ||
      meta.deviceModel === DeviceModel.Legacy6303 ||
      meta.deviceModel === DeviceModel.Legacy6309
    ) {
      image = StihlStatusLegacyMower;
      title = 'RMI Mower';
    } else {
      switch (meta.deviceType) {
        case DeviceType.Imow3:
        case DeviceType.Imow4: {
          image = StihlStatusImowIA02;
          title = 'iMOW IA02';
          break;
        }
        case DeviceType.Imow3Evo:
        case DeviceType.Imow4Evo: {
          image = StihlStatusImowIA02Evo;
          title = 'iMOW IA02 Evo';
          break;
        }
        case DeviceType.Imow7Pro:
        case DeviceType.Imow7Pro4Inch: {
          image = StihlStatusImowIA01Pro;
          title = 'iMOW IA01 Pro';
          break;
        }
        default: {
          if (meta.deviceType.includes('Evo')) {
            image = StihlStatusImowIA01Evo;
            title = 'iMOW IA01 Evo';
          } else {
            image = StihlStatusImowIA01;
            title = 'iMOW IA01';
          }
        }
      }
    }

    return (
      <StihlCardMedia
        image={image}
        title={title}
        backgroundImage={backgroundImage}
      />
    );
  }

  function returnLoadingOrReloadButton(): JSX.Element {
    return isLoading ? (
      <StyledLoadingButton
        loading
        data-testid="refreshButtonLoading"
        onClick={refreshData}
      />
    ) : (
      <StihlTooltipCentered title={t('deviceDetails.deviceStatusTooltip')}>
        <StyledIconButton data-testid="refreshButton" onClick={refreshData}>
          <StihlIconRefresh />
        </StyledIconButton>
      </StihlTooltipCentered>
    );
  }

  return (
    <MuiCard data-testid="deviceStatusCard">
      <DeviceStatusTabTitle
        value={activeTab}
        handleTabsChange={handleTabsChange}
        cardTabs={cardData.cardTabs}
      />
      {renderCardImage()}
      <CardHeader>
        <Typography variant="h3">
          {t('tabs.contentTitles.deviceStatus')}
        </Typography>
        {activeTab === 0 && returnLoadingOrReloadButton()}
      </CardHeader>
      <StyledCardContent>
        <DeviceStatusTabContent
          cardTabs={cardData.cardTabs}
          activeTab={activeTab}
          meta={meta}
        />
        {activeTab === 0 && (
          <Typography variant="caption">
            {t('deviceDetails.deviceStatusLastUpdated', {
              lastUpdated,
              formatParams: {
                lastUpdated: dateTimeFormattingParamsDefault,
              },
            })}
          </Typography>
        )}
      </StyledCardContent>
    </MuiCard>
  );
};

export default DeviceStatusCard;
