import styled from '@emotion/styled';
import Typography from '@mui/material/Typography';
import type { JSX } from 'react';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router';
import StihlButtonSecondary from '../../../../../base/stihl-material-ui/components/stihl-button/stihl-button-secondary';
import StihlModal from '../../../../../base/stihl-material-ui/components/stihl-modal/stihl-modal';
import StihlIconWarning from '../../../../../base/stihl-material-ui/icons/stihl-icon-warning';
import { stihlColor } from '../../../../../base/stihl-material-ui/theme/stihl-style-guide';
import { useAlertStore } from '../../../../app-alert/service/alert-provider';
import type { Card, Meta } from '../../../model';
import { StihlDeviceEnvironment } from '../../../model';
import { moveDevice } from '../../../service/device-api-client/device-api-client';
import { CardBase } from '../generic-card/generic-card';

const StyledTypography = styled(Typography)`
  padding-block-end: 1rem;
`;

const WarningContent = styled.div`
  display: flex;
  margin: 1rem 0 1.5rem;
  padding: 1.5rem;
  background-color: ${stihlColor.yellowLight};
`;

const StyledWarningTypography = styled(Typography)`
  margin-block-end: 0.5rem;
  font-weight: bold;
  font-size: 1rem;
`;

const StyledWarningTextTypography = styled(Typography)`
  font-weight: bold;
  font-size: 1rem;
`;

const TextPositioner = styled.div`
  margin-inline-start: 1rem;
  font-size: 1rem;
`;

export type SystemEnvironmentProps = {
  meta: Meta;
  cardData: Card;
};

export const ChangeEnvironmentCard = ({
  meta,
  cardData,
}: SystemEnvironmentProps): JSX.Element => {
  const { t } = useTranslation();
  const history = useHistory();
  const [openModal, setOpenModal] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [_, setAlert] = useAlertStore();

  let isHidden = true;
  let targetEnvironment = '';

  const deviceEnvironment = meta.deviceEnvironment;
  const deviceId = meta.deviceId;

  if (
    deviceEnvironment === StihlDeviceEnvironment.Uat ||
    deviceEnvironment === StihlDeviceEnvironment.Prd
  ) {
    isHidden = false;
    targetEnvironment =
      deviceEnvironment === StihlDeviceEnvironment.Uat
        ? StihlDeviceEnvironment.Prd
        : StihlDeviceEnvironment.Uat;
  }

  const displayTargetEnvironment =
    targetEnvironment === (StihlDeviceEnvironment.Prd as string)
      ? 'PROD'
      : StihlDeviceEnvironment.Uat.toUpperCase();

  function handleModalOpen(): void {
    setOpenModal(true);
  }
  function handleClose(): void {
    setOpenModal(false);
  }

  function handleAction(): void {
    setIsLoading(true);
    moveDevice(deviceId, targetEnvironment)
      .then(() => {
        setIsLoading(false);
        setOpenModal(false);
        history.push('/');
        setAlert({
          isOpen: true,
          message: `${t(
            'systemEnvironment.moveSuccess',
          )} ${displayTargetEnvironment}`,
          severity: 'success',
        });
        return true;
      })
      .catch(() => {
        setIsLoading(false);
        setOpenModal(false);
        setAlert({
          isOpen: true,
          message: t('systemEnvironment.moveFailed'),
          severity: 'error',
        });
      });
  }

  return (
    <CardBase title={cardData.title}>
      {isHidden ? (
        <StyledTypography data-testid="hiddenSystemEnv">
          {t('systemEnvironment.hidden')}
        </StyledTypography>
      ) : (
        <>
          <StyledTypography data-testid="visibleSystemEnv">
            {t('systemEnvironment.intro')}
          </StyledTypography>
          <StihlButtonSecondary
            onClick={handleModalOpen}
            data-testid="moveButton"
          >
            {t('systemEnvironment.moveToButton')} {displayTargetEnvironment}
          </StihlButtonSecondary>
        </>
      )}

      <StihlModal
        open={openModal}
        onClose={handleClose}
        title={t('systemEnvironment.confirm', {
          environment: displayTargetEnvironment,
        })}
        actionButtonText={t('systemEnvironment.moveDeviceButton')}
        handleActionClick={handleAction}
        isLoading={isLoading}
        data-testid="systemEnvModal"
      >
        <WarningContent>
          <StihlIconWarning />
          <TextPositioner>
            <StyledWarningTypography>
              {t('systemEnvironment.warning')}
            </StyledWarningTypography>
            <StyledWarningTextTypography>
              {t('systemEnvironment.warningText')}
            </StyledWarningTextTypography>
          </TextPositioner>
        </WarningContent>
      </StihlModal>
    </CardBase>
  );
};

export default ChangeEnvironmentCard;
