import styled from '@emotion/styled';
import Slider from '@mui/material/Slider';
import type { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { stihlColor } from '../../../../../base/stihl-material-ui/theme/stihl-style-guide';
import type { RangeFieldValue } from '../../../model';
import CardFieldContent from '../card-field-content/card-field-content';

const SliderWrapper = styled.div`
  display: flex;
  gap: 1rem;
  max-inline-size: 90%;
  margin: 0 auto;

  svg {
    margin-block-start: 1.7rem;
  }
`;

const StyledSlider = styled(Slider)`
  &.MuiSlider-root {
    margin-block-start: 20px;
  }

  &.Mui-disabled {
    color: ${stihlColor.greyDark};
  }

  .MuiSlider-rail {
    block-size: 0.5rem;
    background-color: ${stihlColor.greyBase};
  }

  .MuiSlider-thumb {
    inline-size: 0.5rem;
    block-size: 0.5rem;
    background-color: ${stihlColor.greyDark};
    border: none;

    ::before {
      display: none;
    }
  }

  .MuiSlider-markLabel[data-index='1'],
  .MuiSlider-markLabel[data-index='3'],
  .MuiSlider-markLabel[data-index='5'] {
    inset-block-start: -20px;
  }

  .MuiSlider-markLabelActive {
    font-weight: 700;
  }
`;

type CardFieldRangeProps = {
  name: string;
  value: RangeFieldValue;
};

const CardFieldRange: FC<CardFieldRangeProps> = ({ name, value }) => {
  const { t } = useTranslation();
  return (
    <CardFieldContent name={name}>
      <SliderWrapper>
        {value.minIcon}
        <StyledSlider
          value={value.currentValue}
          marks={value.rangeOptions.map((option) => {
            option.label = t(option.label);
            return option;
          })}
          min={value.rangeOptions.at(0)?.value}
          max={value.rangeOptions.at(-1)?.value}
          color="primary"
          track={false}
          disabled
        />
        {value.maxIcon}
      </SliderWrapper>
    </CardFieldContent>
  );
};

export default CardFieldRange;
