import styled from '@emotion/styled';
import Typography from '@mui/material/Typography';
import type { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { stihlColor } from '../../../../../base/stihl-material-ui/theme/stihl-style-guide';
import type { StatusValue } from '../../../model';
import CardFieldContent from '../card-field-content/card-field-content';

const TriggeredByTypographyStyled = styled(Typography)`
  color: ${stihlColor.fontGrey};
`;

type CardFieldStatusProps = {
  name: string;
  value: StatusValue;
};

const CardFieldStatus: FC<CardFieldStatusProps> = ({ name, value }) => {
  const { t } = useTranslation();
  return (
    <CardFieldContent name={name}>
      <Typography>{t(value.state)}</Typography>
      <Typography>
        <TriggeredByTypographyStyled>
          {t('triggeredBy')}
        </TriggeredByTypographyStyled>{' '}
        {t(value.trigger)}
      </Typography>
    </CardFieldContent>
  );
};

export default CardFieldStatus;
