import styled from '@emotion/styled';
import type { FC, ReactNode } from 'react';
import type { WifiAvailabilityData } from '../../../model';
import CardFieldContent from '../card-field-content/card-field-content';

const StyledList = styled.ul`
  margin: 0;
`;

type CardFieldListProps = {
  name: string;
  value: WifiAvailabilityData[];
  icon?: ReactNode;
};

const CardFieldWifiList: FC<CardFieldListProps> = ({ name, value, icon }) => {
  return (
    <CardFieldContent name={name} icon={icon}>
      <StyledList>
        {value.map((item) => (
          <li key={item.ssid}>{item.ssid}</li>
        ))}
      </StyledList>
    </CardFieldContent>
  );
};

export default CardFieldWifiList;
