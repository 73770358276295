import type { FC } from 'react';
import StihlIconBattery from '../../../../base/stihl-material-ui/icons/stihl-icon-battery';
import StihlIconBlades from '../../../../base/stihl-material-ui/icons/stihl-icon-blades';
import StihlIconBluetooth from '../../../../base/stihl-material-ui/icons/stihl-icon-bluetooth';
import StihlIconDistance from '../../../../base/stihl-material-ui/icons/stihl-icon-distance';
import StihlIconHeart from '../../../../base/stihl-material-ui/icons/stihl-icon-heart';
import StihlIconKey from '../../../../base/stihl-material-ui/icons/stihl-icon-key';
import StihlIconMower from '../../../../base/stihl-material-ui/icons/stihl-icon-mower';
import StihlIconMowing from '../../../../base/stihl-material-ui/icons/stihl-icon-mowing';
import StihlIconPlug from '../../../../base/stihl-material-ui/icons/stihl-icon-plug';
import { CardFieldIcon as CardFieldIconValue } from '../../model';

const CardFieldIcon: FC<{ icon: CardFieldIconValue | undefined }> = ({
  icon,
}) => {
  if (icon === CardFieldIconValue.Heart) {
    return <StihlIconHeart />;
  }
  if (icon === CardFieldIconValue.StateOfCharge) {
    return <StihlIconBattery />;
  }
  if (icon === CardFieldIconValue.Plug) {
    return <StihlIconPlug />;
  }
  if (icon === CardFieldIconValue.Blades) {
    return <StihlIconBlades />;
  }
  if (icon === CardFieldIconValue.Imow) {
    return <StihlIconMower />;
  }
  if (icon === CardFieldIconValue.MowingState) {
    return <StihlIconMowing />;
  }
  if (icon === CardFieldIconValue.Distance) {
    return <StihlIconDistance />;
  }
  if (icon === CardFieldIconValue.Key) {
    return <StihlIconKey />;
  }
  if (icon === CardFieldIconValue.Bluetooth) {
    return <StihlIconBluetooth />;
  }
  return null;
};

export default CardFieldIcon;
