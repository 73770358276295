/* eslint-disable no-secrets/no-secrets */
import MuiCard from '@mui/material/Card';
import Typography from '@mui/material/Typography';
import type { JSX } from 'react';
import { useTranslation } from 'react-i18next';
import StihlButtonPrimary from '../../../../../base/stihl-material-ui/components/stihl-button/stihl-button-primary';
import StihlIconTakeOff from '../../../../../base/stihl-material-ui/icons/stihl-icon-takeoff';
import { useStore } from '../../../../app-authentication/service/authentication-store-provider';
import { AccessLicenses } from '../../../model';
import CardField from '../../card-field/card-field';
import type { GenericCardProps } from '../generic-card/generic-card';
import {
  CardHeader,
  ContentWrapper,
  StyledCardContent,
} from '../generic-card/generic-card';

const ProductionAndIdentificationDataCard = ({
  meta,
  cardData,
}: GenericCardProps): JSX.Element => {
  const { t } = useTranslation();
  const [session] = useStore();

  function handleClick(): void {
    window.open(
      `${process.env.REACT_APP_SSC_BASE_URL}/#/ssc/sso/de/catalog/openresult?openResult=${meta.deviceSerialNumber} `,
    );
  }

  return cardData.cardFields.length > 0 ? (
    <MuiCard data-testid="productionAndIdentificationDataCard">
      <CardHeader>
        <Typography variant="h3">{t(cardData.title)}</Typography>
        {Boolean(
          session.license === AccessLicenses.DealerLicense ||
            session.license === AccessLicenses.DealerUsLicense,
        ) && (
          <StihlButtonPrimary
            data-testid="sscAnchorButton"
            onClick={handleClick}
          >
            <StihlIconTakeOff />
            {t('productionAndIdentificationData.jumpToSSC')}
          </StihlButtonPrimary>
        )}
      </CardHeader>
      <StyledCardContent>
        {cardData.cardFields.map((data) => (
          <ContentWrapper key={data.title}>
            <CardField value={data} meta={meta} />
          </ContentWrapper>
        ))}
      </StyledCardContent>
    </MuiCard>
  ) : (
    <> </>
  );
};
export default ProductionAndIdentificationDataCard;

/* eslint-enable no-secrets/no-secrets */
