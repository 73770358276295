import type { FC } from 'react';
import type {
  CardField as CardFieldValue,
  Customer,
  DateFieldValue,
  DeviceResult,
  ErrorValue,
  JobValue,
  Meta,
  RangeFieldValue,
  ServiceValue,
  StatusValue,
  WarrantyValue,
  WifiAvailabilityData,
} from '../../model';
import { CardFieldType } from '../../model';
import CardFieldAccessPinCode from './card-field-access-pin-code/card-field-access-pin-code';
import CardFieldCellularConnection from './card-field-cellular-connection/card-field-cellular-connection';
import CardFieldCustomerDetails from './card-field-customer-details/card-field-customer-details';
import CardFieldDate from './card-field-date/card-field-date';
import CardFieldDemoMode from './card-field-demo-mode/card-field-demo-mode';
import CardFieldDidToSemanticVersion from './card-field-did-to-semantic-version/card-field-did-to-semantic-version';
import CardFieldError from './card-field-error/card-field-error';
import CardFieldEthernetConnection from './card-field-ethernet-connection/card-field-ethernet-connection';
import CardFieldGpsPositionUpdated from './card-field-gps-position-updated/card-field-gps-position-updated';
import CardFieldIcon from './card-field-icon';
import CardFieldJob from './card-field-job/card-field-job';
import CardFieldMultiDeviceSearchResult from './card-field-multi-device-search-result/card-field-multi-device-search-result';
import CardFieldOperationsData from './card-field-operations-data/card-field-operations-data';
import CardFieldPartOfOrganization from './card-field-part-of-organization/card-field-part-of-organization';
import CardFieldPercentage from './card-field-percentage/card-field-percentage';
import CardFieldRange from './card-field-range/card-field-range';
import CardFieldService from './card-field-service/card-field-service';
import CardFieldStatus from './card-field-status/card-field-status';
import CardFieldSubtitle from './card-field-subtitle/card-field-subtitle';
import CardFieldTeamMower from './card-field-team-mower/card-field-team-mower';
import CardFieldText from './card-field-text/card-field-text';
import CardFieldTextLine from './card-field-text-line/card-field-text-line';
import CardFieldToggle from './card-field-toggle/card-field-toggle';
import CardFieldValidity from './card-field-validity/card-field-validity';
import CardFieldWarrantyData from './card-field-warranty-data/card-field-warranty-data';
import CardFieldWifiConnection from './card-field-wifi-connection/card-field-wifi-connection';
// eslint-disable-next-line import/max-dependencies
import CardFieldWifiList from './card-field-wifi-list/card-field-wifi-list';

type CardFieldProps = {
  value: CardFieldValue;
  meta: Meta;
};

// eslint-disable-next-line sonarjs/cognitive-complexity, complexity, max-lines-per-function
const CardField: FC<CardFieldProps> = ({ value, meta }) => {
  if (
    value.type === CardFieldType.Text ||
    value.type === CardFieldType.DeviceCurrentArea ||
    value.type === CardFieldType.Boolean
  ) {
    return (
      <CardFieldText
        name={value.title}
        value={value.value as string}
        icon={<CardFieldIcon icon={value.icon} />}
      />
    );
  }

  if (value.type === CardFieldType.Percentage) {
    return (
      <CardFieldPercentage
        name={value.title}
        value={value.value as number}
        icon={<CardFieldIcon icon={value.icon} />}
      />
    );
  }

  if (value.type === CardFieldType.Date) {
    return (
      <CardFieldDate name={value.title} value={value.value as DateFieldValue} />
    );
  }

  if (value.type === CardFieldType.Number) {
    return (
      <CardFieldText
        name={value.title}
        value={value.value as number}
        icon={<CardFieldIcon icon={value.icon} />}
      />
    );
  }

  if (value.type === CardFieldType.DeviceError) {
    return (
      <CardFieldError name={value.title} value={value.value as ErrorValue[]} />
    );
  }

  if (value.type === CardFieldType.DeviceServiceRequired) {
    return (value.value as ServiceValue[]).length === 0 ? (
      <CardFieldText
        name={value.title}
        value="deviceDetails.noServicesRequired"
        icon={<CardFieldIcon icon={value.icon} />}
      />
    ) : (
      <CardFieldService
        name={value.title}
        value={value.value as ServiceValue[]}
      />
    );
  }

  if (value.type === CardFieldType.DeviceJobState) {
    return <CardFieldJob name={value.title} value={value.value as JobValue} />;
  }

  if (value.type === CardFieldType.DeviceOverallState) {
    return (
      <CardFieldStatus name={value.title} value={value.value as StatusValue} />
    );
  }

  if (value.type === CardFieldType.DeviceGpsPositionValid) {
    return (
      <CardFieldValidity name={value.title} value={value.value as boolean} />
    );
  }

  if (value.type === CardFieldType.DeviceGpsPositionUpdated) {
    return <CardFieldGpsPositionUpdated value={value.value as number} />;
  }

  if (value.type === CardFieldType.DeviceWifiConnection) {
    return (
      <CardFieldWifiConnection
        name={value.title}
        value={value.value as boolean}
      />
    );
  }

  if (value.type === CardFieldType.DeviceCellularConnection) {
    return (
      <CardFieldCellularConnection
        name={value.title}
        value={value.value as boolean}
      />
    );
  }

  if (value.type === CardFieldType.DeviceEthernetConnection) {
    return (
      <CardFieldEthernetConnection
        name={value.title}
        value={value.value as boolean}
      />
    );
  }

  if (value.type === CardFieldType.DeviceDemoMode) {
    return (value.value as boolean) ? (
      <CardFieldDemoMode name={value.title} />
    ) : null;
  }

  if (value.type === CardFieldType.Subtitle) {
    return value.value === '' ? (
      <CardFieldSubtitle name={value.title} />
    ) : (
      <CardFieldSubtitle name={value.title} value={value.value as boolean} />
    );
  }

  if (value.type === CardFieldType.SearchResult) {
    return (
      <CardFieldMultiDeviceSearchResult
        value={value.value as { results: DeviceResult[] }}
      />
    );
  }

  if (value.type === CardFieldType.DeviceStatusError) {
    return <CardFieldTextLine value={value.value as string} />;
  }

  if (value.type === CardFieldType.Toggle) {
    return (
      <CardFieldToggle
        title={value.title}
        value={value.value as boolean}
        icon={<CardFieldIcon icon={value.icon} />}
      />
    );
  }

  if (value.type === CardFieldType.TeamMower) {
    return (
      <CardFieldTeamMower title={value.title} value={value.value as boolean} />
    );
  }

  if (value.type === CardFieldType.AccessPinCode) {
    return (
      <CardFieldAccessPinCode
        title={value.title}
        value={value.value as boolean}
        icon={<CardFieldIcon icon={value.icon} />}
      />
    );
  }

  if (
    value.type === CardFieldType.PartOfOrganization ||
    value.type === CardFieldType.FleetManaged
  ) {
    return (
      <CardFieldPartOfOrganization
        title={value.title}
        value={value.value as boolean}
        icon={<CardFieldIcon icon={value.icon} />}
      />
    );
  }

  if (
    value.type === CardFieldType.DeviceTotalWorkingTimeOfCurrentCuttingKnifes ||
    value.type === CardFieldType.DeviceTotalDrivenDistance ||
    value.type === CardFieldType.DeviceTotalStartedMowingJobs ||
    value.type === CardFieldType.DeviceTotalWorkingTimeOfCuttingMotor ||
    value.type === CardFieldType.DeviceTotalWorkingSeconds
  ) {
    return (
      <CardFieldOperationsData
        name={value.title}
        icon={<CardFieldIcon icon={value.icon} />}
        value={value.value as number}
      />
    );
  }

  if (value.type === CardFieldType.DeviceWarrantyData) {
    return (
      <CardFieldWarrantyData
        name={value.title}
        value={value.value as WarrantyValue}
      />
    );
  }

  if (value.type === CardFieldType.DeviceWifiAvailability) {
    return (
      <CardFieldWifiList
        name={value.title}
        value={value.value as WifiAvailabilityData[]}
      />
    );
  }

  if (value.type === CardFieldType.Range) {
    return (
      <CardFieldRange
        name={value.title}
        value={value.value as RangeFieldValue}
      />
    );
  }

  if (
    value.type === CardFieldType.DeviceEmbeddedSoftwarePowerPcb ||
    value.type === CardFieldType.DeviceEmbeddedSoftwareDocking ||
    value.type === CardFieldType.DeviceEmbeddedSoftwareSignalPcb ||
    value.type === CardFieldType.SoftwarePackageVersionInitial ||
    value.type === CardFieldType.DeviceEmbeddedSoftwareHmi
  ) {
    return (
      <CardFieldDidToSemanticVersion
        name={value.title}
        value={value.value as string}
      />
    );
  }

  if (value.type === CardFieldType.Customer) {
    return (
      <CardFieldCustomerDetails value={value.value as Customer} meta={meta} />
    );
  }

  return null;
};

export default CardField;
