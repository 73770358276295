import styled from '@emotion/styled';
import CircularProgress from '@mui/material/CircularProgress';
import Grid from '@mui/material/Grid';
import MenuItem from '@mui/material/MenuItem';
import Typography from '@mui/material/Typography';
import type { JSX } from 'react';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import StihlSelect from '../../../../../base/stihl-material-ui/components/stihl-select/stihl-select';
import { useAlertStore } from '../../../../app-alert/service/alert-provider';
import { useStore } from '../../../../app-authentication/service/authentication-store-provider';
import type { Card, Meta, StihlDeviceTag } from '../../../model';
import { changeTag } from '../../../service/device-api-client/device-api-client';
import {
  LEFT_COLUMN_SIZE,
  RIGHT_COLUMN_SIZE,
} from '../../card-field/card-field-content/card-field-content';
import { CardBase } from '../generic-card/generic-card';

const StyledGrid = styled(Grid)`
  align-items: center;
  margin: 0.5rem 0;
`;

export type DeviceTagProps = {
  deviceId: string | undefined;
  fetchedTag: string | undefined;
  flags: StihlDeviceTag[];
};

export const DeviceTag = ({
  flags,
  deviceId,
  fetchedTag,
}: DeviceTagProps): JSX.Element => {
  const { t } = useTranslation();
  const [tag, setTag] = useState(fetchedTag);
  const [isLoading, setIsLoading] = useState(false);
  const [_, setAlert] = useAlertStore();

  function handleTagChange(event: React.ChangeEvent<HTMLInputElement>): void {
    setTag(event.target.value);
    if (event.target.value !== '') {
      setIsLoading(true);
      changeTag(event.target.value, deviceId)
        .then(() => {
          setIsLoading(false);
          const alertMessage = t('tag.tagChanged');
          return setAlert({
            isOpen: true,
            message: alertMessage,
            severity: 'success',
          });
        })
        .catch(() => {
          setIsLoading(false);
          setTag(fetchedTag);
          const alertMessage = t('tag.tagChangeFailed');
          return setAlert({
            isOpen: true,
            message: alertMessage,
            severity: 'error',
          });
        });
    }
  }

  return (
    <div style={{ display: 'flex' }}>
      <StihlSelect
        id="tag"
        name="tag"
        value={tag}
        sx={{ inlineSize: '250px' }}
        onChange={handleTagChange}
        data-testid="deviceTagSelectField"
      >
        {flags.map((singleFlag) => (
          <MenuItem key={singleFlag} value={singleFlag}>
            {t(singleFlag)}
          </MenuItem>
        ))}
      </StihlSelect>
      {isLoading && (
        <CircularProgress
          color="inherit"
          size={20}
          style={{ alignSelf: 'center', marginLeft: '1rem' }}
        />
      )}
    </div>
  );
};

export type FlagsCardProps = {
  meta: Meta;
  cardData: Card;
};

export const FlagsCard = ({ meta, cardData }: FlagsCardProps): JSX.Element => {
  const { t } = useTranslation();
  const [session] = useStore();

  return (
    <CardBase title={cardData.title}>
      <StyledGrid container data-testid="flagsCard">
        <Grid item xs={LEFT_COLUMN_SIZE} pr=".25rem">
          <Typography fontWeight="bold">{t('tag.currentDeviceTag')}</Typography>
        </Grid>
        <Grid item xs={RIGHT_COLUMN_SIZE} pl=".25rem">
          <DeviceTag
            flags={session.deviceFlags}
            deviceId={meta.deviceId}
            fetchedTag={meta.deviceFlag}
          />
        </Grid>
      </StyledGrid>
    </CardBase>
  );
};

export default FlagsCard;
