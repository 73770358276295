import styled from '@emotion/styled';
import Typography from '@mui/material/Typography';
import type { JSX, Dispatch, SetStateAction } from 'react';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import StihlButtonSecondaryLoading from '../../../../../base/stihl-material-ui/components/stihl-button/stihl-button-secondary-loading';
import StihlIconArrowDown from '../../../../../base/stihl-material-ui/icons/stihl-icon-arrow-down';
import { useAlertStore } from '../../../../app-alert/service/alert-provider';
import { TabsType } from '../../../../app-shell/model/app.models';
import type { Card, DiagnosisSessionData, Meta } from '../../../model';
import { CardBase } from '../generic-card/generic-card';
import { getDiagnosisDetails } from './diagnosis-details/service/diagnosis-details-api-client';

const StyledArrowIcon = styled(StihlIconArrowDown)`
  margin-inline-start: 0.5rem;
  transform: rotate(270deg);
`;

export const DiagnosisCard = ({
  meta,
  cardData,
  setVisibleTab,
  setDiagnosisData,
}: {
  meta: Meta;
  cardData: Card;
  setVisibleTab: (tabType: TabsType) => void;
  setDiagnosisData: Dispatch<
    SetStateAction<DiagnosisSessionData[] | undefined>
  >;
}): JSX.Element => {
  const { t } = useTranslation();
  const [isLoading, setIsLoading] = useState(false);
  const [_, setAlert] = useAlertStore();

  function onButtonClick(): void {
    setIsLoading(true);
    getDiagnosisDetails(meta.deviceSerialNumber)
      .then((response) => {
        setDiagnosisData(response);
        setIsLoading(false);
        return setVisibleTab(TabsType.DiagnosisData);
      })
      .catch(() => {
        setIsLoading(false);
        setAlert({
          isOpen: true,
          message: t('diagnosisData.alertMessage'),
          severity: 'error',
        });
      });
  }
  return (
    <CardBase title={cardData.title}>
      <Typography data-testid="diagnosisCard">
        {t('diagnosisData.info')}
      </Typography>
      <Typography>{t('diagnosisData.buttonHint')}</Typography>
      <br />

      <StihlButtonSecondaryLoading
        loading={isLoading}
        data-testid="fetchDiagnosisData"
        onClick={onButtonClick}
      >
        {t('diagnosisData.button')} <StyledArrowIcon color="white" />
      </StihlButtonSecondaryLoading>
    </CardBase>
  );
};

export default DiagnosisCard;
