import styled from '@emotion/styled';
import Typography from '@mui/material/Typography';
import type { FC } from 'react';
import { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { useDateFormatting } from '../../../../../base/date-formatting/date-formatting';
import StihlIconTextFlexbox from '../../../../../base/stihl-material-ui/components/stihl-icon-text-box/stihl-icon-text-flexbox';
import StihlTooltip from '../../../../../base/stihl-material-ui/components/stihl-tooltip/stihl-tooltip';
import StihlIconInfo from '../../../../../base/stihl-material-ui/icons/stihl-icon-info';
import { stihlColor } from '../../../../../base/stihl-material-ui/theme/stihl-style-guide';
import type { ServiceValue } from '../../../model';
import { DeviceTabsContext } from '../../tabs/device-tabs.context';
import CardFieldContent from '../card-field-content/card-field-content';

const StyledLink = styled.button`
  color: ${stihlColor.blueDark};
  text-decoration: underline;
  background-color: transparent;
  border: none;

  &:hover {
    cursor: pointer;
  }
`;

type CardFieldServiceProps = {
  value: ServiceValue[];
  name: string;
};

const ServiceDateTypographyStyled = styled(Typography)`
  color: ${stihlColor.fontGrey};
`;

const TooltipIcon: FC = () => {
  const { t } = useTranslation();

  return (
    <StihlTooltip
      title={t('deviceDetails.errorsAndServicesTooltip')}
      placement="bottom"
      data-testid="requiredServicesTooltip"
    >
      <StihlIconInfo />
    </StihlTooltip>
  );
};

const CardFieldService: FC<CardFieldServiceProps> = ({ value, name }) => {
  const { openTab } = useContext(DeviceTabsContext);
  const { toLocaleDateTimeString } = useDateFormatting();

  function handleClick(): void {
    openTab('tabs.dtcCodes');
  }

  return (
    <CardFieldContent name={name} icon={<TooltipIcon />}>
      {value.map((data: ServiceValue) => (
        <StihlIconTextFlexbox key={data.code}>
          <div style={{ marginLeft: '0.5rem' }}>
            <Typography>
              <StyledLink
                onClick={handleClick}
                data-testid="requiredServiceLink"
              >
                {data.code}
              </StyledLink>{' '}
              -{' '}
              <ServiceDateTypographyStyled>
                {toLocaleDateTimeString(new Date(data.activeSince))}
              </ServiceDateTypographyStyled>
            </Typography>
          </div>
        </StihlIconTextFlexbox>
      ))}
    </CardFieldContent>
  );
};

export default CardFieldService;
